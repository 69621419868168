<template>
  <v-col cols="12" sm="8">
    <v-expansion-panels flat tile popout>
      <v-expansion-panel
        class="footer__accordion"
        v-for="m in menu"
        :key="m.id"
      >
        <v-expansion-panel-header class="footer__accordion__header">{{
          m.title
        }}</v-expansion-panel-header>
        <v-expansion-panel-content v-for="im in m.innerMenu" :key="im.id">
          <router-link class="footer__accordion__content" :to="im.path">{{
            im.title
          }}</router-link>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-col>
</template>

<script>
export default {
  data () {
    return {
      menu: [
        {
          id: 1,
          title: 'Alokasko.com',
          innerMenu: [
            {
              title: 'Hakkımızda',
              path: '/hakkimizda'
            },
            {
              title: 'Sıkça Sorulan Sorular',
              path: '/sikca-sorulan-sorular'
            },
            {
              title: 'Hasar Anında ',
              path: '/hasar-aninda'
            },
            {
              title: 'Kampanyalar',
              path: '/kampanyalar'
            },
            {
              title: 'Reklam Filmleri',
              path: '/reklam-filmleri'
            },
            {
              title: 'İletişim',
              path: '/iletisim'
            }
          ]
        },
        {
          id: 2,
          title: 'Ürünlerimiz',
          innerMenu: [
            {
              title: 'Trafik Sigortası',
              path: '/zorunlu-trafik-sigortasi'
            },
            {
              title: 'Kasko Sigortası',
              path: '/arac-kasko-sigortasi'
            },
            {
              title: 'Dask-Deprem Sigortası',
              path: '/dask-zorunlu-deprem-sigortasi'
            },
            {
              title: 'Konut Sigortası',
              path: '/konut-sigortasi'
            },
            {
              title: 'Sağlık Sigortası',
              path: '/saglik-sigortasi'
            },
            {
              title: 'İşyeri Sigortası',
              path: '/isyeri-sigortasi'
            },
            {
              title: 'Nakliyat Sigortası',
              path: '/nakliyat-sigortasi'
            },
            {
              title: 'Ferdi Kaza Sigortası',
              path: '/ferdi-kaza-sigortasi'
            },
            {
              title: 'Kefalet Sigortası',
              path: '/kefalet-sigortasi'
            },
            {
              title: 'Alacak Sigortası',
              path: '/devlet-destekli-alacak-sigortasi'
            },
            {
              title: 'Cep Telefonu Sigortası',
              path: '/cep-telefonu-sigortasi'
            },
            {
              title: 'İkinci El Araç Garanti Sigortası',
              path: '/ikinci-el-arac-garanti-sigortasi'
            },
            {
              title: 'Bireysel Emeklilik Sistemi (BES)',
              path: '/bes-bireysel-emeklilik-sistemi'
            }
          ]
        },
        {
          id: 3,
          title: 'Hesaplama Aracı',

          innerMenu: [
            {
              id: 1,
              title: 'Kasko Değer Hesaplama',
              path: '/kasko-deger-hesaplama'
            }
          ]
        },
        {
          id: 4,
          title: 'Bilgi Merkezi',
          innerMenu: [
            {
              id: 2,
              title: 'Sigorta Sözlük',
              path: '/sigorta-sozlugu'
            },
            {
              id: 3,
              title: 'Kullanıcı Sözleşmesi',
              path: '/kullanici-sozlesmesi'
            },
            {
              id: 4,
              title: 'Kişisel Verilerin Korunması',
              path: '/kisisel-verilerin-korunmasi'
            },
            {
              id: 5,
              title: 'Çerez Politikası',
              path: '/cerez-politikasi'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style></style>

<template>
  <div>
    <h4>Alokasko.com</h4>
    <ul>
      <li v-for="(item, i) in about" :key="i">
        <router-link :to="item.path">{{ item.title }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      about: [
        {
          title: "Hakkımızda",
          path: "/hakkimizda"
        },
        {
          title: "Sıkça Sorulan Sorular",
          path: "/sikca-sorulan-sorular"
        },
        {
          title: "Hasar Anında ",
          path: "/hasar-aninda"
        },
        // {
        //   title: 'Kampanyalar',
        //   path: '/kampanyalar'
        // },
        // {
        //   title: 'Reklam Filmleri',
        //   path: '/reklam-filmleri'
        // },
        {
          title: "İletişim",
          path: "/iletisim"
        },
        {
          title: "Blog",
          path: "/blog"
        },
        {
          title: "Sözlük",
          path: "/sigorta-sozlugu"
        }
      ]
    }
  }
}
</script>

<style></style>

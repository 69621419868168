<template>
  <v-col cols="12" md="10"  xl="8" class="d-flex justify-center  flex-wrap">
    <v-col
      cols="5"
      lg="3"
      class="footer__social"
      v-for="(btn, i) in buttons"
      :key="i"
    >
      <div>
        <span><i :class="btn.iconClass"></i></span
        ><a
          type="button"
          class="footer__social-btn"
          target="_blank"
          rel="noreferrer"
          :href="btn.path"
          >{{ btn.title }}</a
        >
      </div>
    </v-col>
  </v-col>
</template>

<script>
export default {
  data () {
    return {
      buttons: [
        {
          title: 'Alokaskocom',
          iconClass: 'fab  fa-facebook-f fa-2x',
          path: 'https://www.facebook.com/alokaskocom'
        },
        {
          title: 'Alokaskocom',
          iconClass: 'fab fa-twitter fa-2x',
          path: 'https://twitter.com/alokaskocom'
        },
        {
          title: 'Alokaskocom',
          iconClass: 'fab fa-instagram fa-2x',
          path: 'https://www.instagram.com/alokaskocom/'
        },
        {
          title: 'Alokaskocom',
          iconClass: 'fab  fa-linkedin-in fa-2x',
          path: 'https://www.linkedin.com/company/alokasko-com/'
        }
      ]
    }
  }
}
</script>

<style></style>

<template>
  <div>
    <a
      :href="m.target"
      target="_blank"
      rel="noreferrer"
      v-for="m in mobileSocial"
      :key="m.id"
    >
      <v-btn class="mx-4 mobile-social__icon" icon>
        <v-icon size="24px">
          {{ m.icon }}
        </v-icon>
      </v-btn>
    </a>
  </div>
</template>

<script>
export default {
  data () {
    return {
      mobileSocial: [
        {
          id: 1,
          title: 'facebook',
          icon: 'mdi-facebook',
          target: 'https://www.facebook.com/alokaskocom'
        },
        {
          id: 2,
          title: 'facebook',
          icon: 'mdi-twitter',
          target: 'https://twitter.com/alokaskocom'
        },
        {
          id: 3,
          title: 'facebook',
          icon: 'mdi-instagram',
          target: 'https://www.instagram.com/alokaskocom/'
        },
        {
          id: 4,
          title: 'facebook',
          icon: 'mdi-linkedin',
          target: 'https://www.linkedin.com/company/alokasko-com/'
        }
      ]
    }
  }
}
</script>

<style></style>

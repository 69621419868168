<template>
  <div>
    <div class="get_quote--fixed">
      <!-- <v-dialog v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn large class="get-quote-btn mr-2" v-bind="attrs" v-on="on"
            >Hemen Teklif Al</v-btn
          >
        </template>

        <v-card shaped class="py-5 px-10">
          <v-card-title class="success--text"
            >Teklif Almak İstediğiniz Ürünü Seçiniz</v-card-title
          >
          <v-card-actions>
            <v-autocomplete
              outlined
              label="Teklif Tipi"
              v-model="selectedProduct"
              :items="categories"
              item-value="value"
              item-text="name"
              hide-details="auto"
            ></v-autocomplete>
          </v-card-actions>
          <v-card-actions>
            <v-row no-gutters class="justify-end">
              <v-btn
                :disabled="!selectedProduct"
                @click="navigateToCategory"
                class="success"
                >Devam</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
      <!-- </div> -->
      <!-- <div class="get_quote--fixed--mobile">
      <v-dialog v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn large class="get-quote-btn mr-2" v-bind="attrs" v-on="on"
            >Hemen Teklif Al</v-btn
          >
        </template>
        <v-card shaped class="py-5 px-10">
          <v-card-title class="success--text"
            >Teklif Almak İstediğiniz Ürünü Seçiniz</v-card-title
          >
          <v-card-actions>
            <v-autocomplete
              outlined
              label="Teklif Tipi"
              v-model="selectedProduct"
              :items="categories"
              item-value="value"
              item-text="name"
              hide-details="auto"
            ></v-autocomplete>
          </v-card-actions>
          <v-card-actions>
            <v-row no-gutters class="justify-end">
              <v-btn
                :disabled="!selectedProduct"
                @click="navigateToCategory"
                class="success"
                >Devam</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dialog: false,
      selectedProduct: null,
      categories: [
        {
          name: 'Trafik Sigortası',
          value: 'CompulsoryTrafficInsurance'
        },
        {
          name: 'Kasko Sigortası',
          value: 'MotorInsurance'
        },
        {
          name: 'Dask Deprem Sigortası',
          value: 'DaskInsurance'
        },
        {
          name: 'Konut Sigortası',
          value: 'HousingInsurance'
        },
        {
          name: 'Özel Sağlık Sigortası',
          value: 'PrivateHealthInsurance'
        },
        {
          name: 'Sağlık Sigortası',
          value: 'HealthInsurance'
        },
        {
          name: 'Tamamlayıcı Sağlık Sigortası',
          value: 'ComplementHealthInsurance'
        },
        {
          name: 'Doğum Sağlık Sigortası',
          value: 'BirthInsurance'
        },
        {
          name: 'Seyahat Sağlık Sigortası',
          value: 'TravelHealthInsurance'
        },
        {
          name: 'İşyeri Sigortası',
          value: 'CompanyInsurance'
        },
        {
          name: 'Nakliyat Sigortası',
          value: 'ShippingInsurance'
        },
        {
          name: 'Ferdi Kaza Sigortası',
          value: 'PersonalAccidentInsurance'
        },
        {
          name: 'Kefalet Sigortası',
          value: 'SuretyInsurance'
        },
        {
          name: 'Alacak Sigortası',
          value: 'CreditInsurance'
        },
        {
          name: 'Cep Telefonu Sigortası',
          value: 'MobilePhoneInsurance'
        },
        {
          name: 'İkinci El Araç Garanti Sigortası',
          value: 'SecondhandGuaranteeInsurance'
        },
        {
          name: 'Bireysel Emeklilik',
          value: 'IndividualAnnuityInsurance'
        },
        {
          name: 'Yabancı Sağlık Sigortası',
          value: 'ForeignHealthInsurance'
        }
      ]
    }
  },
  methods: {
    navigateToCategory () {
      if (this.selectedProduct) {
        this.$router.push({ name: this.selectedProduct })
      }
    }
  }
}
</script>

<style></style>

<template>
  <v-row class="flex-column align-center mt-5 py-15 px-15">
    <v-expansion-panels flat>
      <v-expansion-panel v-for="m in menu" :key="m.id">
        <v-expansion-panel-header>
          {{ m.title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content v-for="c in m.categories" :key="c.path">
          <router-link :to="c.path">{{ c.name }}</router-link>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row no-gutters>
      <a href="http://blog.alokasko.com" class="mx-10 my-10" target="_blank"
        >BLOG</a
      >
      <router-link class="mx-10 my-10" to="/subemiz-olun">
        ŞUBEMİZ OLUN
      </router-link>
      <router-link class="mx-10 my-10" to="/sigorta-sozlugu">
        SÖZLÜK
      </router-link>
    </v-row>

    <v-row no-gutters class="justify-center mt-10">
      <a class="phone-text d-flex" href="tel://02163716777"
        ><v-img
          class="mr-2"
          color="transparent"
          src="@/assets/icons/phone-call.svg"
        ></v-img
        ><span>0216 371 67 77</span></a
      >
    </v-row>
  </v-row>
</template>

<script>
export default {
  props: {
    drawer: {
      type: Boolean,
      require: true
    }
  },
  data() {
    return {
      menu: [
        {
          id: 1,
          title: "SİGORTA",
          categories: [
            {
              name: "Trafik Sigortası",
              path: "/zorunlu-trafik-sigortasi"
            },
            {
              name: "Kasko Sigortası",
              path: "/arac-kasko-sigortasi"
            },
            {
              name: "Dask-Deprem Sigortası",
              path: "/dask-zorunlu-deprem-sigortasi"
            },
            {
              name: "Konut Sigortası",
              path: "/konut-sigortasi"
            },
            {
              name: "Özel Sağlık Sigortası",
              path: "/ozel-saglik-sigortasi"
            },
            {
              name: "Tamamlayıcı Sağlık Sigortası",
              path: "/tamamlayici-saglik-sigortasi"
            },
            {
              name: "Doğum Sağlık Sigortası",
              path: "/hamilelik-dogum-sigortasi"
            },
            {
              name: "Seyahat Sağlık Sigortası",
              path: "/seyahat-saglik-sigortasi"
            },
            {
              name: "Yabancı Sağlık Sigortası",
              path: "/yabanci-uyruklu-saglik-sigortasi"
            },
            {
              name: "İşyeri Sigortası",
              path: "/isyeri-sigortasi"
            },
            {
              name: "Nakliyat Sigortası",
              path: "/nakliyat-sigortasi"
            },
            {
              name: "Ferdi Kaza Sigortası",
              path: "/ferdi-kaza-sigortasi"
            },
            {
              name: "Kefalet Sigortası",
              path: "/kefalet-sigortasi"
            },
            {
              name: "Alacak Sigortası",
              path: "/devlet-destekli-alacak-sigortasi"
            },
            {
              name: "Cep Telefonu Sigortası",
              path: "/cep-telefonu-sigortasi"
            },
            {
              name: "İkinci El Araç Garanti Sigortası",
              path: "/ikinci-el-arac-garanti-sigortasi"
            },
            {
              name: "Bireysel Emeklilik Sistemi (BES)",
              path: "/bes-bireysel-emeklilik-sistemi"
            }
          ]
        },
        {
          id: 2,
          title: "ALOKASKO",
          categories: [
            {
              name: "Hakkımızda",
              path: "/hakkimizda"
            },
            {
              name: "Sıkça Sorulan Sorular",
              path: "/sikca-sorulan-sorular"
            },
            {
              name: "İletişim",
              path: "/iletisim"
            },
            {
              name: "Hasar Anında",
              path: "/hasar-aninda"
            },
            {
              name: "Kasko Değer Hesaplama",
              path: "/kasko-deger-hesaplama"
            },
            {
              name: "Kampanyalar",
              path: "/kampanyalar"
            },
            {
              name: "Reklam Filmleri",
              path: "/reklam-filmleri"
            }
          ]
        }
      ]
    }
  }
}
</script>

<style></style>

<template>
  <div>
    <h4>Ürünlerimiz</h4>
    <ul>
      <li v-for="p in products" :key="p.path">
        <router-link :to="p.path">{{ p.title }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      products: [
        {
          title: 'Trafik Sigortası',
          path: '/zorunlu-trafik-sigortasi'
        },
        {
          title: 'Kasko Sigortası',
          path: '/arac-kasko-sigortasi'
        },
        {
          title: 'Dask-Deprem Sigortası',
          path: '/dask-zorunlu-deprem-sigortasi'
        },
        {
          title: 'Konut Sigortası',
          path: '/konut-sigortasi'
        },
        {
          title: 'Sağlık Sigortası',
          path: '/saglik-sigortasi'
        },
        {
          title: 'İşyeri Sigortası',
          path: '/isyeri-sigortasi'
        },
        {
          title: 'Nakliyat Sigortası',
          path: '/nakliyat-sigortasi'
        },
        {
          title: 'Ferdi Kaza Sigortası',
          path: '/ferdi-kaza-sigortasi'
        },
        {
          title: 'Kefalet Sigortası',
          path: '/kefalet-sigortasi'
        },
        {
          title: 'Alacak Sigortası',
          path: '/devlet-destekli-alacak-sigortasi'
        },
        {
          title: 'Cep Telefonu Sigortası',
          path: '/cep-telefonu-sigortasi'
        },
        {
          title: 'İkinci El Araç Garanti Sigortası',
          path: '/ikinci-el-arac-garanti-sigortasi'
        },
        {
          title: 'Bireysel Emeklilik Sistemi (BES)',
          path: '/bes-bireysel-emeklilik-sistemi'
        }
      ]
    }
  }
}
</script>

<style></style>

<template>
  <div>
    <v-app-bar
      app
      height="90px"
      color="white"
      elevate-on-scroll
      class="d-flex flex-column justify-center navbar"
    >
      <v-container class="d-flex align-center justify-space-between mx-auto">
        <router-link to="/">
          <img
            width="206"
            height="47"
            alt="alokasko logo"
            src="@/assets/navbar/alo-kasko-nav-logo.svg"
          />
        </router-link>
        <!-- MENU LİNKLERİ V-FOR'A GEÇİRİLEBİLİR -->
        <ul class="d-none d-md-flex menu align-center">
          <li
            class="menu-item d-flex align-center"
            @mouseover="modal = true"
            @mouseleave="modal = false"
          >
            <span class="menu__title">SİGORTA</span>
            <ul class="dropdown">
              <li class="dropdown__subcategory">
                <div class="dropdown__title">Araç</div>
                <ul class="dropdown__submenu">
                  <li>
                    <router-link to="/zorunlu-trafik-sigortasi"
                      >Trafik Sigortası</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/arac-kasko-sigortasi"
                      >Kasko Sigortası</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="dropdown__subcategory">
                <div class="dropdown__title">Ev</div>
                <ul class="dropdown__submenu">
                  <li>
                    <router-link to="/dask-zorunlu-deprem-sigortasi"
                      >Dask-Deprem Sigortası</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/konut-sigortasi"
                      >Konut Sigortası</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="dropdown__subcategory">
                <div class="dropdown__title">Sağlık</div>
                <ul class="dropdown__submenu">
                  <li>
                    <router-link to="/ozel-saglik-sigortasi"
                      >Özel Sağlık Sigortası</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/tamamlayici-saglik-sigortasi"
                      >Tamamlayıcı Sağlık Sigortası</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/hamilelik-dogum-sigortasi"
                      >Doğum Sağlık Sigortası</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/seyahat-saglik-sigortasi"
                      >Seyahat Sağlık Sigortası</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/yabanci-uyruklu-saglik-sigortasi"
                      >Yabancı Sağlık Sigortası</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="dropdown__subcategory">
                <div class="dropdown__title">Diğer</div>
                <ul class="dropdown__submenu">
                  <li>
                    <router-link to="/isyeri-sigortasi"
                      >İşyeri Sigortası</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/nakliyat-sigortasi"
                      >Nakliyat Sigortası</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/ferdi-kaza-sigortasi"
                      >Ferdi Kaza Sigortası</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/kefalet-sigortasi"
                      >Kefalet Sigortası</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/devlet-destekli-alacak-sigortasi"
                      >Alacak Sigortası</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/cep-telefonu-sigortasi"
                      >Cep Telefonu Sigortası</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/ikinci-el-arac-garanti-sigortasi"
                      >İkinci El Araç Garanti Sigortası</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/bes-bireysel-emeklilik-sistemi"
                      >Bireysel Emeklilik Sistemi (BES)</router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li
            class="menu-item d-flex align-center"
            @mouseover="modal = true"
            @mouseleave="modal = false"
          >
            <span class="menu__title ">ALOKASKO</span>
            <ul class="dropdown">
              <li class="dropdown__subcategory">
                <ul class="dropdown__submenu">
                  <li>
                    <router-link to="/hakkimizda">Hakkımızda</router-link>
                  </li>
                  <li>
                    <router-link to="/sikca-sorulan-sorular"
                      >Sıkça Sorulan Sorular</router-link
                    >
                  </li>
                  <li><router-link to="/iletisim">İletişim</router-link></li>
                </ul>
              </li>
              <ul class="dropdown__submenu">
                <li>
                  <router-link to="/hasar-aninda">Hasar Anında</router-link>
                </li>
                <li>
                  <router-link to="/kasko-deger-hesaplama"
                    >Kasko Değer Hesaplama</router-link
                  >
                </li>
                <!-- <li>
                  <router-link to="/kampanyalar">Kampanyalar</router-link>
                </li> -->
                <!-- <li>
                  <router-link to="/reklam-filmleri"
                    >Reklam Filmleri</router-link
                  >
                </li> -->
              </ul>
            </ul>
          </li>
          <li class="menu-item d-flex align-center">
            <a
              href="https://blog.alokasko.com"
              class="menu__title"
              target="_blank"
              >BLOG</a
            >
          </li>
          <li class="menu-item d-flex align-center">
            <router-link class="menu__title" to="/subemiz-olun"
              >ŞUBEMİZ OLUN</router-link
            >
          </li>
          <li class="menu-item d-flex align-center">
            <router-link class="menu__title" to="/sigorta-sozlugu"
              >SÖZLÜK</router-link
            >
          </li>
        </ul>

        <v-toolbar-items class="d-none d-md-flex">
          <a class="phone-text d-flex" href="tel://02163716777"
            ><v-img
              class="mr-2"
              color="transparent"
              src="@/assets/icons/phone-call.svg"
            ></v-img
            ><span class="d-none d-lg-flex">0216 371 67 77</span></a
          >
        </v-toolbar-items>
        <v-app-bar-nav-icon
          @click="drawer = true"
          class="d-md-none"
          color="success"
        ></v-app-bar-nav-icon>
      </v-container>
    </v-app-bar>
    <!-- mega menu ovarlay -->
    <div class="modal-nav" v-if="modal"></div>
    <v-navigation-drawer
      class="mobile-menu"
      v-model="drawer"
      temporary
      fixed
      tag="aside"
      height="100%"
      width="100%"
    >
      <mobile-menu />
    </v-navigation-drawer>
  </div>
</template>

<script>
import mobileMenu from "@/component/navbar/MobileMenu"
export default {
  components: {
    mobileMenu
  },
  data() {
    return {
      modal: false,
      drawer: false
    }
  }
}
</script>

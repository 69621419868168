<template>
  <div>
    <h4>Hesaplama Aracı</h4>
    <ul>
      <li v-for="(item, i) in help" :key="i">
        <router-link :to="item.path">{{ item.title }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      help: [
        {
          title: 'Kasko Değer Hesaplama',
          path: '/kasko-deger-hesaplama'
        }
      ]
    }
  }
}
</script>

<style></style>

<template>
  <v-footer class="footer">
    <v-row no-gutters class="justify-space-around">
      <v-col cols="12" md="4" lg="3">
        <div
          class="footer__content d-flex justify-center  align-center align-md-left flex-column"
        >
          <v-col
            cols="8"
            offset="2"
            offset-sm="1"
            offset-md="0"
            sm="6"
            md="10"
            lg="10"
          >
            <!--Footer logo starts -->
            <v-img
              width="80%"
              aspect-ratio="16/9"
              alt="alokasko"
              src="@/assets/footer/alo-kasko-footer-logo.svg"
            ></v-img>
            <!--Footer logo ends -->
          </v-col>
          <!-- Mobile expansion panel starts -->
          <app-mobile-accordion class="mt-10 d-md-none" />
          <!-- Mobile expansion panel ends -->
          <v-col
            cols="10"
            lg="10"
            class=" footer__content__outer d-flex flex-column align-center align-md-start"
          >
            <div class="d-flex align-center">
              <a href="tel://02163716777" class="d-flex align-center">
                <p>
                  <i class="footer__content__icon fas fa-phone-alt fa-2x"></i>
                </p>
                <p class="footer__content__text text-center text-md-left">
                  0216 371 67 77
                </p>
              </a>
            </div>
            <div class="d-flex align-center">
              <a href="mailto:info@alokasko.com" class="d-flex align-center">
                <p>
                  <i
                    class="footer__content__icon fas fa-envelope-open-text fa-2x"
                  ></i>
                </p>
                <p class="footer__content__text text-center text-md-left">
                  info@alokasko.com
                </p>
              </a>
            </div>
            <div
              class="d-flex align-center "
              v-for="(c, i) in companyInfo"
              :key="i"
            >
              <p class="d-none d-md-block"><i :class="c.iconClass"></i></p>
              <p class="footer__content__text text-center text-md-left">
                {{ c.text }}
              </p>
            </div>
            <app-mobile-social class="d-md-none" />
          </v-col>
        </div>
      </v-col>
      <v-col cols="7" md="8" class="d-none d-md-flex">
        <v-row no-gutters class="footer__menu justify-space-between">
          <app-footer-about />
          <app-footer-products />
          <app-footer-help />
          <app-footer-information />
        </v-row>
      </v-col>
    </v-row>

    <!-- <app-footer-info />-->
    <!-- Large screen social buttons start -->
    <v-row class="justify-center d-none d-md-flex">
      <app-footer-social-buttons class="d-none d-md-flex" />
    </v-row>
    <!-- Large screen social buttons ends -->
    <fixed-buttons></fixed-buttons>
  </v-footer>
</template>

<script>
import appFooterAbout from '@/component/footer/AppFooterAbout'
import appFooterProducts from '@/component/footer/AppFooterProducts'
import appFooterHelp from '@/component/footer/AppFooterHelp'
import appFooterInformation from '@/component/footer/AppFooterInformation'
import appFooterSocialButtons from '@/component/footer/AppFooterSocialButtons'
import appMobileAccordion from '@/component/footer/AppMobileAccordion'
import appMobileSocial from '@/component/footer/AppMobileSocial'
import fixedButtons from '@/component/fixed-buttons/FixedButtons'

export default {
  components: {
    appFooterAbout,
    appFooterProducts,
    appFooterHelp,
    appFooterInformation,
    appFooterSocialButtons,
    appMobileAccordion,
    appMobileSocial,
    fixedButtons
  },
  data () {
    return {
      companyInfo: [
        {
          iconClass: 'footer__content__icon fas fa-map-marked-alt fa-2x',
          text: 'Yeşilbağlar Mah. Dumanlar Sk. No:1 Ofis 27-28 PENDİK/İstanbul (Helis Gold Plaza)'
        }
      ]
    }
  }
}
</script>

<style></style>

<template>
  <div>
    <h4>Bilgi Merkezi</h4>
    <ul>
      <li v-for="(item, i) in about" :key="i">
        <router-link :to="item.path">{{ item.title }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      about: [
        // {
        //   title: 'Blog',
        //   path: '/blog'
        // },
        // {
        //   title: 'Sigorta Sözlük',
        //   path: '/sigorta-sozlugu'
        // },
        {
          title: 'Kişisel Verilerin Korunması',
          path: '/kisisel-verilerin-korunmasi'
        },
        {
          title: 'Çerez Politikası',
          path: '/cerez-politikasi'
        },
        {
          title: 'Kullanıcı Sözleşmesi',
          path: '/kullanici-sozlesmesi'
        }
      ]
    }
  }
}
</script>

<style></style>
